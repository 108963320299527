import type { WritableComputedRef } from 'vue'

export const useCurrency = () => {
  const currencyValues = { 'united states': 'USD', us: 'USD', canada: 'CAD', ca: 'CAD' }
  const getValueWithCurrency = ({
    amount,
    currency,
    country,
    round = true,
    locale
  }: {
    amount: number
    currency?: string
    country?: string
    round?: boolean
    locale?: WritableComputedRef<string>
  }) => {
    if (!currency && country) currency = currencyValues[country.toLowerCase() as keyof typeof currencyValues]

    try {
      if (!locale) locale = useI18n().locale

      return `${new Intl.NumberFormat(locale.value, {
        style: 'currency',
        currency: currency ?? 'CAD',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: round ? 0 : 2,
        maximumFractionDigits: round ? 0 : 2
      }).format(amount)}${currency ? ` ${currency}` : ''}`
    } catch {
      // likely an old browser
      const fixedAmount = round ? Number(amount).toFixed(2) : Number(amount).toFixed(0)
      return `${fixedAmount} ${currency ?? ''}`.trim()
    }
  }

  const getCurrency = (country?: string) => {
    if (country == null) return undefined
    return currencyValues[country.toLowerCase() as keyof typeof currencyValues]
  }

  return {
    getValueWithCurrency,
    getCurrency
  }
}
