import type { Rv } from '~/types/api'

export const datadogAddError = (error: Error) => {
  ddRum().addError(error, { cause: error?.message ?? 'Unknown' })
}

export const datadogStartView = (path: string) => {
  try {
    ddRum().startView({
      name: path
    })
  } catch (e) {
    console.error(e)
  }
}

export const trackRvEvent = (eventName: string, rv: Rv, additionalData: any) => {
  const eventData = {
    rvId: rv.id,
    listingCountry: rv.location.countryCode?.toLowerCase(),
    listingRegion: rv.location.regionCode,
    listingCity: rv.location.city,
    listingType: rv.category,
    rvType: rv.class,
    dealerName: rv.dealer.name,
    totalPrice: rv.listPrice,
    currency: useCurrency().getCurrency(rv.location.countryCode),
    listingDescriptionChars: rv.description?.length,
    rvMake: rv.make,
    rvModel: rv.model,
    rvYear: rv.year,
    rvMileage: rv.mileage,
    rvWeight: rv.weight ? Math.trunc(rv.weight) : undefined,
    slideOut: rv.slideOuts,
    sleepingCapacity: rv.sleepingCapacity
  }

  segmentTrack(eventName, { ...eventData, ...additionalData })
}

export const segmentTrack = (eventName: string, data: any, pageSource?: string) => {
  const additionalData = {
    pageSource: pageSource ?? getRouteBaseName()
  }

  analytics().track(eventName, {
    ...data,
    ...additionalData
  })
}

export const segmentPage = ({ to, from }: { to: any; from: any }) => {
  window?.analytics?.page(to.name, {
    path: to.path,
    referrer: from.name !== null ? from.path : document.referrer,
    search: JSON.stringify(to.query) !== '{}' ? JSON.stringify(to.query) : '',
    title: document.title,
    url: document.location.href
  })
}

const ddRum = () =>
  window?.DD_RUM ?? {
    addError(e: Error, t: any) {},
    startView(t: any) {},
    setUser(t: any) {},
    removeUser() {}
  }

const analytics = () =>
  window?.analytics ?? {
    identify(s: number, t: any) {},
    track(s: string, t: any) {}
  }

export const redditTrack = ({ event }: { event: string }) => {
  window?.rdt?.('track', event)
}
