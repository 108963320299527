import pino from 'pino'

const initParams = {
  browser: {
    asObject: true
  }
}

export const logger = pino(initParams)

export const captureError = (error: Error, context: Record<string, any> | undefined = undefined) => {
  const errorDetails = {
    message: error.message,
    stack: error.stack,
    type: error.name,
    context
  };

  logger.error(errorDetails);

  try {
    if (process.client && window.DD_RUM) {
      window.DD_RUM.addError(error, {
        context: {
          ...context,
          errorType: error.name,
          errorMessage: error.message
        }
      });
    } else {
      if (process.client) {
        console.warn("Datadog RUM is not initialized, but an error occurred:", error);
      }
    }
  } catch (error) {
    console.error("Failed to log error to Datadog RUM:", error);
  }
}
