import { default as indexYvalk2vKegMeta } from "/home/vsts/work/1/s/web/src/pages/index.vue?macro=true";
import { default as index9e846gndH2Meta } from "/home/vsts/work/1/s/web/src/pages/[...place]/index.vue?macro=true";
import { default as _91slug_93OPWBT5uxPIMeta } from "/home/vsts/work/1/s/web/src/pages/brand/[slug].vue?macro=true";
import { default as indexxmuifvQsb8Meta } from "/home/vsts/work/1/s/web/src/pages/buy-an-rv/index.vue?macro=true";
import { default as _91slug_93eNpVyCs5qDMeta } from "/home/vsts/work/1/s/web/src/pages/c/[slug].vue?macro=true";
import { default as indexYWPZxJAPxiMeta } from "/home/vsts/work/1/s/web/src/pages/get-listed/dealer/index.vue?macro=true";
import { default as index9T5aPJ6Cl0Meta } from "/home/vsts/work/1/s/web/src/pages/get-listed/private/index.vue?macro=true";
import { default as indexicw5oTs4HWMeta } from "/home/vsts/work/1/s/web/src/pages/health-check/index.vue?macro=true";
import { default as _91slug_9384I7ldDrVnMeta } from "/home/vsts/work/1/s/web/src/pages/rv/[slug].vue?macro=true";
import { default as searchfriWwa4vTxMeta } from "/home/vsts/work/1/s/web/src/pages/search.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexYvalk2vKegMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "place___en",
    path: "/en/:place(.*)*",
    meta: index9e846gndH2Meta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/[...place]/index.vue").then(m => m.default || m)
  },
  {
    name: "place___fr",
    path: "/fr/:place(.*)*",
    meta: index9e846gndH2Meta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/[...place]/index.vue").then(m => m.default || m)
  },
  {
    name: "brand-slug___en",
    path: "/en/brand/:slug()",
    meta: _91slug_93OPWBT5uxPIMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brand-slug___fr",
    path: "/fr/brand/:slug()",
    meta: _91slug_93OPWBT5uxPIMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: "buy-an-rv___en",
    path: "/en/buy-an-rv",
    component: () => import("/home/vsts/work/1/s/web/src/pages/buy-an-rv/index.vue").then(m => m.default || m)
  },
  {
    name: "buy-an-rv___fr",
    path: "/fr/buy-an-rv",
    component: () => import("/home/vsts/work/1/s/web/src/pages/buy-an-rv/index.vue").then(m => m.default || m)
  },
  {
    name: "c-slug___en",
    path: "/en/c/:slug()",
    meta: _91slug_93eNpVyCs5qDMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/c/[slug].vue").then(m => m.default || m)
  },
  {
    name: "c-slug___fr",
    path: "/fr/c/:slug()",
    meta: _91slug_93eNpVyCs5qDMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/c/[slug].vue").then(m => m.default || m)
  },
  {
    name: "get-listed-dealer___en",
    path: "/en/get-listed/dealer",
    component: () => import("/home/vsts/work/1/s/web/src/pages/get-listed/dealer/index.vue").then(m => m.default || m)
  },
  {
    name: "get-listed-dealer___fr",
    path: "/fr/get-listed/dealer",
    component: () => import("/home/vsts/work/1/s/web/src/pages/get-listed/dealer/index.vue").then(m => m.default || m)
  },
  {
    name: "get-listed-private___en",
    path: "/en/get-listed/private",
    component: () => import("/home/vsts/work/1/s/web/src/pages/get-listed/private/index.vue").then(m => m.default || m)
  },
  {
    name: "get-listed-private___fr",
    path: "/fr/get-listed/private",
    component: () => import("/home/vsts/work/1/s/web/src/pages/get-listed/private/index.vue").then(m => m.default || m)
  },
  {
    name: "health-check___en",
    path: "/en/health-check",
    meta: indexicw5oTs4HWMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/health-check/index.vue").then(m => m.default || m)
  },
  {
    name: "health-check___fr",
    path: "/fr/health-check",
    meta: indexicw5oTs4HWMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/health-check/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexYvalk2vKegMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexYvalk2vKegMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-slug___en",
    path: "/en/rv/:slug()",
    component: () => import("/home/vsts/work/1/s/web/src/pages/rv/[slug].vue").then(m => m.default || m)
  },
  {
    name: "rv-slug___fr",
    path: "/fr/vr/:slug()",
    component: () => import("/home/vsts/work/1/s/web/src/pages/rv/[slug].vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchfriWwa4vTxMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/recherche",
    meta: searchfriWwa4vTxMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/search.vue").then(m => m.default || m)
  }
]