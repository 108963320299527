<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const $config = useRuntimeConfig()
const { locale } = useI18n()

useInitApp()

function useInitApp() {
  onMounted(async () => {
    const { openSession } = await useBraze()
    // Open a new Braze session
    openSession()
  })
}

useHead(
  {
    link: [
      {
        rel: 'icon',
        type: 'image/svg',
        href: '/favicons/favicon.svg'
      }
    ],
    htmlAttrs: {
      lang: locale
    }
  },
  {
    mode: 'server'
  }
)
</script>
