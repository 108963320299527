import { captureError } from '~/lib/logger'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error: any) => {
    captureError(error)
  }

  nuxtApp.hook('vue:error', (error) => captureError(error as Error))
  nuxtApp.hook('app:error', (error) => captureError(error))
})
