export default {
  base: () => import("/home/vsts/work/1/s/web/src/layouts/base.vue").then(m => m.default || m),
  content: () => import("/home/vsts/work/1/s/web/src/layouts/content.vue").then(m => m.default || m),
  default: () => import("/home/vsts/work/1/s/web/src/layouts/default.vue").then(m => m.default || m),
  empty: () => import("/home/vsts/work/1/s/web/src/layouts/empty.vue").then(m => m.default || m),
  headerless: () => import("/home/vsts/work/1/s/web/src/layouts/headerless.vue").then(m => m.default || m),
  homepage: () => import("/home/vsts/work/1/s/web/src/layouts/homepage.vue").then(m => m.default || m),
  reset: () => import("/home/vsts/work/1/s/web/src/layouts/reset.vue").then(m => m.default || m),
  "search-with-footer": () => import("/home/vsts/work/1/s/web/src/layouts/search-with-footer.vue").then(m => m.default || m),
  search: () => import("/home/vsts/work/1/s/web/src/layouts/search.vue").then(m => m.default || m)
}