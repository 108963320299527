import { Loader } from '@googlemaps/js-api-loader'

declare module '#app' {
  interface NuxtApp {
    $googleMapsLoader: Loader
  }
}

declare module 'nuxt/dist/app/nuxt' {
  interface NuxtApp {
    $googleMapsLoader: Loader
  }
}

export default defineNuxtPlugin(() => {
  const $config = useRuntimeConfig()

  return {
    provide: {
      googleMapsLoader: new Loader({
        apiKey: $config.public.google.apiKey
      })
    }
  }
})
